
























































































































































































































































































































































































































































































































import ViewModel from '@/views/service/InhabitantAdvantage.ts';
export default ViewModel;
